.service-main {
    background-color: #eff4f7;
}

.service-item {
    display: inline-flex;
    align-items: center;
    margin: 25px 0;
    font-weight: bold;
}

.service-item-key {
    background: white;
    display: block;
    min-width: 50px;
    height: 50px;
    text-align: center;
    font-size: 18px;
    line-height: 50px;
    color: #000;
    overflow: hidden;
    border-radius: 50px;
    margin-right: 10px;
}


.service-item-value {
    text-align: start;
}

@media (max-width: 991px) {
    .service-item {
       justify-content: start;
    }

    .service-item-key {
        min-width: 40px;
        height: 40px;
        font-size: 20px;
        line-height: 40px;
    }
}
