html, body {
  font-size: 100%; /* 62.5% of 16px = 10px */
}

html, body *{
  font-family: Georgia, Times,Times New Roman,serif;
}

.site-btn {
  background: #ffa600;
  padding: 0.75rem 1rem;
  font-weight: bold;
  border: none;
  font-family: 'Arial',Arial,sans-serif;
  border-radius: 1.5625rem;
}

.section-header {
  font-weight: 600;
  color: #000;
  margin-bottom:1.25rem;
  text-align: center;
}

.section-description {
  max-width: 37.5rem;
  margin: auto;
}

.error {
  color: red;
  font-size: 0.75rem;
}

@media screen and (min-width: 87.5rem) { /* 1400 px */
  body {
    font-size: 120%;
  }
}
