.contacts__social {
    background: #eee;
    padding: 1.25rem;
    color: white;
    text-align: center;
}

.contacts__social img {
    width: 3.125rem;
    height: 3.125rem;
    padding: 0.3125rem;
}

@media (max-width: 36rem) { /* 576px */
    .contacts-email {
        font-size: 0.9375rem;
    }
}