.swiper-slide{
    background: none!important;
}

.swiper-pagination{
    position: relative!important;
}

.swiper-pagination-bullet {
    background: white!important;
    border: 1px solid black!important;
}

.swiper-pagination-bullet-active {
    background: black!important;
}

.feedback {
    background: #eee;
}

.feedback__text {
    padding: 35px;
    border-radius: 10px;
}

.feedback__image {
    width: 80px!important;
    height: 80px!important;
    margin: auto;
}

.feedback__author-name {
    padding-bottom: 20px;
    font-size: 20px;
    line-height: 1.35;
    font-family: 'Georgia',serif;
    font-weight: 600;
    color: #000;
    align-self: center;
    margin: 10px 0 0 0;
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-slide img .feedback__image{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.star-rating {
    max-height:18px;
    max-width: 18px;
}