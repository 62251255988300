.header{
    text-align: center;
}

.header > .row {
    height: 100%;
    align-items: center;
}

.top-background {
    background-image: url(../../images/top-background.webp);
    width: inherit;
    background-size: cover;
    background-position: center;
}

.header__site-name {
    font-size: 1.7rem;
}

.header__contacts {
    font-family: 'Georgia',serif;
    line-height: 1.55;
    font-weight: 400;
    color: #1008ff !important;
    text-decoration: none;
}

.header__author-photo {
    box-shadow: 1.25rem 1.25rem 2.6875rem 0 rgb(0 0 0 / 90%);
    margin-top: 0.9375rem;
}

img.header__logo {
    max-height: 4.5rem;
}

.header__author-name {
    font-size: 1.25rem;
    margin-bottom: 0.625rem;
}

.header__author-descripton {
    font-size: 0.9375rem;
}

@media screen and (min-width: 48rem) {
    .header__logo {
        margin-right: 0.75rem;
    }
}


@media (min-width: 62rem) { /* 992 px */
    .header__logo {
        max-height: 4rem;
    }

    .header__contacts-button {
        margin-left: 0.75rem;
    }
}


@media (min-width: 87.5rem)  { /* 1400px */

    img.header__author-photo {
        max-width: 296px;
    }

    .header__author-name {
        font-size: 1.875rem;
        line-height: 2.4375rem;
    }

    .header__contacts {
        font-size: 1.05rem;
    }
}


