p {
    text-align: justify;
}

.content-float-block {
    border-top: 1px solid grey;
    float: right;
    width: 40%;
    color: #4906f9;
    font-size: 1.5rem;
    padding-top: 0.625rem;
    margin: 0 0.9373rem 0.9373rem;
}

.content-fa-icon {
    min-width: 3.75rem;
    margin-bottom: 1.5625rem;
}

@media (max-width: 48rem) { /* 768px */
    .content-float-block {
        float: none;
        width: 100%;
        margin: 0 auto 0.9373rem;
    }
}