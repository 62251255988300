.separator {
    line-height: 0.3125rem;
    min-height: 0.3125rem;
    background: #000;
    opacity: .1;
}

.scrollToTopBtn {
    position: fixed;
    bottom: 4.0625rem;
    right: 1.875rem;
    z-index: 100;
    min-height: 3.125rem;
    min-width: 3.125rem;
    border: none;
    background: none;
    display: none;
}

.scrollToTopBtn img {
    height: 100%;
    width: 100%;
}

.visible {
    display: block!important;
}
